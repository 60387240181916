/**
 * This method pulls the region ID out of the locale string. That string
 * should have the format region_id!building_id!area_id!room_id
 *
 * @param localeId
 * @returns {*|null}
 */
export function getRegionId(localeId) {
    if (localeId === undefined) {
        return null;
    }

    if (localeId.trim() === "") {
        return null;
    }

    // Split this ID
    const localeIdArray = localeId.split('!');

    // Return the first element. This is the region's ID
    return localeIdArray[0];
}

/**
 * This method pulls the building ID out of the locale string. That string
 * should have the format region_id!building_id!area_id!room_id
 *
 * @param localeId
 * @returns {*|null}
 */
export function getBuildingId(localeId) {
    if (localeId === undefined) {
        return null;
    }

    if (localeId.trim() === "") {
        return null;
    }

    // Split this ID
    const localeIdArray = localeId.split('!');

    // If this ID doesn't have at least 2 parts, return null
    if (localeIdArray.length < 2) {
        return null
    }

    // Return the second element. This is the building's ID
    return localeIdArray[1];
}

/**
 * This method pulls the building ID out of the locale string. That string
 * should have the format region_id!building_id!area_id!room_id
 *
 * @param localeId
 * @returns {*|null}
 */
export function getAreaId(localeId) {
    if (localeId === undefined) {
        return null;
    }

    if (localeId.trim() === "") {
        return null;
    }

    // Split this ID
    const localeIdArray = localeId.split('!');

    // If this ID doesn't have at least 3 parts, return null
    if (localeIdArray.length < 3) {
        return null
    }

    // Return the third element. This is the area's ID
    return localeIdArray[2];
}

/**
 * This method pulls the room ID out of the locale string. That string
 * should have the format region_id!building_id!area_id!room_id
 *
 * @param localeId
 * @returns {*|null}
 */
export function getRoomId(localeId) {
    if (localeId === undefined) {
        return null;
    }

    if (localeId.trim() === "") {
        return null;
    }

    // Split this ID
    const localeIdArray = localeId.split('!');

    // If this ID doesn't have at least 4 parts, return null
    if (localeIdArray.length < 4) {
        return null
    }

    // Return the third element. This is the area's ID
    return localeIdArray[3];
}