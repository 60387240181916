import ChartGenerator from "./chart_generator";

export default class NoChartGenerator extends ChartGenerator {

    // Since we don't display a chart, we don't need any of these helper functions
    constructor(chartType, labelFunction, labelReducerFunction, valueFunction) {
        super(null, null, null, null)
    }

    isChartEnabled() {
        return false;
    }
}