import {
    DEFAULT_ORDER_BY,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT,
    DEVICES_PAGINATION_TOKEN_ID,
    LOAD_MORE_BUTTON_ID,
    TABLE_ID
} from "../../constants/manage_devices_constants";
import _ from "lodash";
import { getAreaId, getBuildingId, getRegionId, getRoomId } from "../locales/locale_id_helpers";

/**
 * This helper function creates the query parameters that power filtering
 * in the /devices/table/list API.
 *
 * @param paginationToken
 * @param localeId
 * @param deviceTypeId
 * @param manufacturerId
 * @param modelId
 * @param deploymentStatusId
 * @param updatedAtDate
 * @param withAreas
 * @param withRooms
 * @returns {{page_size: number}}
 */
export function getDeviceQueryInputParameters(
    paginationToken,
    localeId,
    deviceTypeId = null,
    manufacturerId = null,
    modelId = null,
    deploymentStatusId = null,
    updatedAtDate = null,
    withAreas = null,
    withRooms = null,
    withDeploymentStatus = null
) {
    const queryParameters = {
        page_size: DEFAULT_PAGE_SIZE,
    }

    // If a pagination token is provided, add it to the query attributes
    if (!_.isEmpty(paginationToken)) {
        queryParameters['pagination_token'] = paginationToken;
    }

    // If a locale is provided, add it to the query attributes
    if (!_.isEmpty(localeId)) {
        queryParameters['region'] = getRegionId(localeId);
        queryParameters['building'] = getBuildingId(localeId);
        queryParameters['area'] = getAreaId(localeId);
        queryParameters['room'] = getRoomId(localeId);
    }

    // If the device type id is provided, add it to the query attributes
    if (!_.isEmpty(deviceTypeId)) {
        queryParameters['device_type'] = deviceTypeId;
    }

    // If the manufacturer id is provided, add it to the query attributes
    if (!_.isEmpty(manufacturerId)) {
        queryParameters['manufacturer'] = manufacturerId;
    }

    // If the model id is provided, add it to the query attributes
    if (!_.isEmpty(modelId)) {
        queryParameters['model'] = modelId;
    }

    // If the deployment status id is provided, add it to the query attributes
    if (!_.isEmpty(deploymentStatusId)) {
        queryParameters['deployment_status'] = deploymentStatusId;
    }

    // If the withAreas parameter isn't null or empty, add it ot the parameter hash
    if (!_.isEmpty(withAreas)) {
        queryParameters['with_areas'] = withAreas.toString();
    }

    // If the withRooms parameter isn't null or empty, add it to the parameter hash
    if (!_.isEmpty(withRooms)) {
        queryParameters['with_rooms'] = withRooms.toString();
    }

    if (!_.isEmpty(withDeploymentStatus)) {
        queryParameters['with_deployment_status'] = withDeploymentStatus.toString();
    }

    return queryParameters;
}

export function loadPageOfDevices(paginationToken = null, orderBy = DEFAULT_ORDER_BY, sort = DEFAULT_SORT) {
    async function execute_query(setPaginationToken, paginationToken) {

        // TODO: REMOVE THIS DEBUGGING STATEMENT
        // console.log(`loadPageOfDevices: sort=[${sort}], orderBy=[${orderBy}], paginationToken=[${paginationToken}]`);

        $('#detailsHolder').bootstrapTable('showLoading');

        // Issue the request
        $.ajax({
            url: '/tables/devices/list',
            dataType: 'json',
            data: {
                pagination_token: paginationToken,
                page_size: DEFAULT_PAGE_SIZE,
                sort: sort,
                order_by: orderBy
            }
        }).promise().then(devicesJson => {
            // Add the returned devices to the table
            const tableElement = document.getElementById(TABLE_ID);

            $('#detailsHolder').bootstrapTable('append', devicesJson.rows);

            setPaginationToken(devicesJson.pagination_token)

            // Hide the loading animation
            $('#detailsHolder').bootstrapTable('hideLoading');
        }).catch((error) => {
            $('#detailsHolder').bootstrapTable('hideLoading');
            console.error(error);
            return new Error('Error fetching devices. Please try again.');
        });
    }

    execute_query(setPaginationToken, paginationToken);
}

export function getCurrentPaginationToken() {
    return document.getElementById(DEVICES_PAGINATION_TOKEN_ID).value;
}

export function setPaginationToken(paginationToken) {
    // If the pagination token is null, reset it to be empty instead
    paginationToken = paginationToken === null ? "" : paginationToken

    // Set the current pagination token. If it's empty, we've hit the last page of results.
    // Let's disable and hide the button as well if that happens.
    if (paginationToken.trim() === "") {
        document.getElementById(LOAD_MORE_BUTTON_ID).disabled = true;
        document.getElementById(LOAD_MORE_BUTTON_ID).style.visibility = 'hidden';
    } else {
        document.getElementById(LOAD_MORE_BUTTON_ID).disabled = false;
    }

    document.getElementById(DEVICES_PAGINATION_TOKEN_ID).value = paginationToken;
}

export function onEditableSaveEventHandler(field, row, rowIndex, oldValue, element) {
    if (field !== undefined) {
        $.ajax({
            url: `/tables/devices/edit`,
            type: 'POST',
            data: { column: field, info: row },
            success: function (result) {
                if (result.status !== 200) {
                    // Get the table. We need to revert this attempted change
                    $(`#${TABLE_ID}`).bootstrapTable('updateCell', { index: rowIndex, field: field, value: oldValue });

                    // TODO: Turn the cell red for half a second
                    // I can't figure out how to make this work.
                    // const originalBackgroundColor = element.style.property.backgroundColor;
                    // element.style.property.backgroundColor = '#FFFAFA'
                    // setTimeout(function () {
                    //     element.style.property.backgroundColor = originalBackgroundColor;
                    // }, 500);
                }
            }, error: function (jqXHR, textStatus, errorThrown) {
                return console.log("AJAX Error: %s, exception: %s" + textStatus, errorThrown);
            }
        });
    }
}

/**
 * This method redirects the user to the clicked device's update device page
 *
 * @param field
 * @param value
 * @param row
 * @param element the clicked HTML element
 */
export function rowDoubleClickedEventHandler(field, value, row, element) {
    // Grab the device's id from the row's hidden ID column
    const itemId = element.closest('tr').find('td.id').text();

    // Construct the target URL and go to the device's update page
    window.location = '/devices/update-device?id=' + itemId;
}

/**
 * This method executes an action when the user has scrolled down to the bottom 95% of
 * the page.
 *
 * This is currently dead code but it took me a minute to figure out how to implement it.
 * https://bootstrap-table.com/docs/api/events/#onscrollbody
 *
 * @param tableBody
 */
export function onScrollBodyEventHandler(tableBody) {
    // This is the scroll position. It's measured from the top of the table's viewing area
    // #detailsHolder is the table HTML element
    const scrollPosition = $('#detailsHolder').bootstrapTable('getScrollPosition');

    // This is the height of the viewable area of the table
    const viewableTableHeight = tableBody.innerHeight();

    // This is the total height of the table, both viewable and scrolled.
    const totalTableHeight = $('#detailsHolder').innerHeight();

    // Get the position as a percentage, e.g. 82.94882828492%
    const percentageScrolled = ((scrollPosition + viewableTableHeight) / totalTableHeight) * 100;

    // If we've scrolled past 95% of the viewable area,
    if (percentageScrolled >= 95) {
        // const currentPaginationToken = getCurrentPaginationToken();
        // console.log(`DeviceTableController::#onScrollBodyEventHandler: fetching next page. percentageScrolled=[${percentageScrolled}], paginationToken=[${currentPaginationToken}]`);
        // loadPageOfDevices(currentPaginationToken);
    }
}

function tom_select() {
    document.querySelectorAll('select.deployment_status.tom-select').forEach((element) => {
        const classNames = ['ts-wrapper', 'tomselected']
        if (classNames.some(className => element.classList.contains(className))) {
        } else {
            let settings = {
                create: false,
                plugins: ['change_listener'],
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                maxOptions: null // tom-select will truncate the select to 50 options by default
            };
        
            new TomSelect(element, settings);
        }
    });
};

export function sortEventHandler(name, order) {
    tom_select()
}