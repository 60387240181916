export default class DataFactory {

    constructor(labelFunction, labelReducerFunction, valueFunction) {
        this._labelFunction = labelFunction;
        this._labelReducerFunction = labelReducerFunction;
        this._valueFunction = valueFunction;
    }

    getLabels(devices) {
        // Get the universe of labels to display
        return this._labelReducerFunction(devices);
    }

    /**
     * Documentation for pie chart data format:
     * https://www.chartjs.org/docs/latest/charts/doughnut.html#data-structure
     * @returns {*[]}
     */
    getData(devices) {
        // Get the unique elements
        const labelArray = this._labelReducerFunction(devices);

        // Get the unique elements mapped to their counts
        // Looks something like { "element1": 17, "element2": 19, "element3": 7 }
        const labelsMappedToTheirFrequencyCount = devices.reduce((accumulator, value) => {
            // Re-use the same function to get this device's label. It expects an array and returns an array.
            let labelName = this._labelFunction(value);

            // Use a helper method to parse out the device's value and add that to the running sum.
            // This is typically just an incremental counter (e.g. when you see a Printer, increment
            // the Printer count by 1). Sometimes, we're not counting and instead sum a field (like
            // cost). This helper function (the valueFunction) allows us to extract the value out of
            // the device.
            accumulator[labelName] = (accumulator[labelName] || 0) + this._valueFunction(value);
            return accumulator;
        }, {});

        // Create an output array
        const results = new Array(labelArray.length);
        for (let index = 0; index < labelArray.length; index++) {
            const labelAtIndex = labelArray[index];
            if (labelsMappedToTheirFrequencyCount[labelAtIndex] !== undefined) {
                results[index] = labelsMappedToTheirFrequencyCount[labelAtIndex];
            } else {
                results[index] = 0;
            }
        }

        return results;
    }

}