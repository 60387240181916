import { accounting } from "accounting";

export function counterValue(device) {
    return 1;
}

/**
 * The device cost chart aggregates cost by deployment status
 *
 * @param device
 * @returns {*}
 */
export function deviceCostValue(device) {
    return accounting.unformat(device['device_type']['cost']);
}

/**
 * We currently use this method to abstract away the locale string provided
 * to toLocaleString. We need to provide the options returned by the
 * getLocaleStringOptions function in this file to prevent decimal points from
 * being dropped from round numbers.
 *
 * @returns {string}
 */
export function getLocaleStringLocale() {
    return 'en-US';
}

/**
 * We currently use this method to abstract away the options provided to
 * toLocaleString. We need to provide the options to prevent decimal points
 * from being dropped from round numbers.
 *
 * @returns {{maximumFractionDigits: number, minimumFractionDigits: number}}
 */
export function getLocaleStringOptions() {
    return { minimumFractionDigits: 2, maximumFractionDigits: 2 }
}