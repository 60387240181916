import { deploymentStatusLabel, deviceTypeLabel } from "./label_function";
import { accounting } from "accounting/accounting";

export function deploymentStatusLabelReducer(devices) {
    // Get every device's deployment status. There will be duplicates in this array
    const deploymentStatuses = devices.map(device => deploymentStatusLabel(device));

    // Get the unique elements mapped to their counts
    // Looks something like { "element1": 17, "element2": 19, "element3": 7 }
    const labelsMappedToTheirFrequencyCount = deploymentStatuses.reduce((accumulator, value) => {
        accumulator[value] = (accumulator[value] || 0) + 1;
        return accumulator;
    }, {});

    // Create an output array
    const results = [];

    // Sort the elements by their frequency counts, descending
    Object.keys(labelsMappedToTheirFrequencyCount).sort(function (a, b) {
        return labelsMappedToTheirFrequencyCount[a] - labelsMappedToTheirFrequencyCount[b]
    }).reverse().forEach(function (key) {
        // Stash the label in the output array
        results.push(key);
    });

    // Return
    return results;
}

export function deviceTypeLabelReducer(devices) {
    // Get each device's device type. This array will have duplicates
    const deviceTypes = devices.map(device => deviceTypeLabel(device));

    // Get the unique elements mapped to their counts
    // Looks something like { "element1": 17, "element2": 19, "element3": 7 }
    const labelsMappedToTheirFrequencyCount = deviceTypes.reduce((accumulator, value) => {
        accumulator[value] = (accumulator[value] || 0) + 1;
        return accumulator;
    }, {});

    // Create an output array
    const results = [];

    // Sort the elements by their frequency counts, descending
    Object.keys(labelsMappedToTheirFrequencyCount).sort(function (a, b) {
        return labelsMappedToTheirFrequencyCount[a] - labelsMappedToTheirFrequencyCount[b]
    }).reverse().forEach(function (key) {
        // Stash the label in the output array
        results.push(key);
    });

    // Return
    return results;
}

export function updatedAtLabelReducer(devices) {
    // Get the current timestamp
    const currentTimestamp = Date.now();

    // Compute one day in milliseconds. We'll step by this value
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    // Get the lower window
    const twoWeeksInMilliseconds = 2 * 7 * oneDayInMilliseconds;

    // Create an output array
    const labelArray = []

    // Get each date between now and 14 days ago
    const now = new Date(currentTimestamp + oneDayInMilliseconds);
    for (let date = new Date(currentTimestamp - twoWeeksInMilliseconds); date <= now; date.setDate(date.getDate() + 1)) {
        labelArray.push(new Date(date).toDateString());
    }

    // Return the array
    return labelArray;
}

export function costByByStatusLabelReducer(devices) {
    // Grab the device's deployment status mapped to its cost.
    // Looks something like [{ "Future State": 199.99 }, { "Future State": 299.99 }, { "Current State": 399.99 }]
    const deploymentStatusMappedToIndividualCosts = [];
    devices.forEach((device) => {
        deploymentStatusMappedToIndividualCosts.push({ [deploymentStatusLabel(device)]: accounting.unformat(device['device_type']['cost']) });
    })

    const deploymentStatusesMappedToTheirTotalCost = deploymentStatusMappedToIndividualCosts.reduce((accumulator, value) => {
        // get the key of the current hash
        const key = Object.keys(value)[0];

        // add the current value to the accumulator.
        // The toFixed(2) method rounds to the nearest 2 decimal places.
        accumulator[key] = (accumulator[key] || 0) + value[key];

        return accumulator;
    }, {});

    // Create an output array
    const results = [];

    // Sort the elements by their frequency counts, descending
    Object.keys(deploymentStatusesMappedToTheirTotalCost).sort(function (a, b) {
        return deploymentStatusesMappedToTheirTotalCost[a] - deploymentStatusesMappedToTheirTotalCost[b]
    }).reverse().forEach(function (key) {
        // Stash the label in the output array
        results.push(key);
    });

    return results;
}