import ReportGenerator from "./report_generator";
import {
    AREA_DATA_FIELD,
    BAR_CHART,
    BUILDING_DATA_FIELD,
    COST_DATA_FIELD,
    COUNT_FIELD,
    DEPLOYMENT_STATUS_FIELD,
    ROOM_DATA_FIELD,
    TABLE_ID,
    TOTAL_COST_FIELD,
    TOTAL_DEVICES_REPORT_TITLE,
    UPDATED_AT_DATA_FIELD
} from "../../constants/reports_constants";
import { getDeviceQueryInputParameters } from "../../shared/tables/devices_table";

export default class TotalDevicesReportGenerator extends ReportGenerator {

    _getGraphTitle() {
        return TOTAL_DEVICES_REPORT_TITLE;
    }

    _hideUnusedColumns() {
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('hideColumn', COST_DATA_FIELD);
        table.bootstrapTable('hideColumn', COUNT_FIELD);
        table.bootstrapTable('hideColumn', DEPLOYMENT_STATUS_FIELD);
        table.bootstrapTable('hideColumn', TOTAL_COST_FIELD);
        table.bootstrapTable('hideColumn', UPDATED_AT_DATA_FIELD);
    }

    _showUsedColumns() {
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('showColumn', BUILDING_DATA_FIELD);
        table.bootstrapTable('showColumn', AREA_DATA_FIELD);
        table.bootstrapTable('showColumn', ROOM_DATA_FIELD);
    }

    _getChartType() {
        return BAR_CHART;
    }

    _getDeviceQueryInputParameters(
        paginationToken,
        localeId = null,
        deviceTypeId = null,
        manufacturerId = null,
        modelId = null,
        deploymentStatusId = null,
        updatedAtDate = null
    ) {
        const baseQueryParameters = getDeviceQueryInputParameters(
            paginationToken,
            localeId,
            deviceTypeId,
            manufacturerId,
            modelId,
            deploymentStatusId,
            updatedAtDate
        );

        baseQueryParameters['with_buildings'] = 'true'
        baseQueryParameters['with_areas'] = 'true'
        baseQueryParameters['with_rooms'] = 'true'

        return baseQueryParameters;
    }

}