export function getSelectedDeploymentStatusId(device) {
// Find the first element with "selected" attribute set to true
    const selectedElement = device['deployment_status'].find((deploymentStatus) => deploymentStatus.selected === true);

    // Extract the "name" field from the selected element (if found)
    return selectedElement?.id;
}

export function getSelectedDeploymentStatusName(device) {
    // Find the first element with "selected" attribute set to true
    const selectedElement = device['deployment_status'].find((deploymentStatus) => deploymentStatus.selected === true);

    // Extract the "name" field from the selected element (if found)
    return selectedElement?.name;
}