// HTML ELEMENT CONSTANTS
export const GRAPH_CANVAS_ID = "graph-holder";
export const TABLE_ID = "table-holder";
export const CHART_TITLE_ID = "chart-title";

// REPORT TITLES
export const CHANGES_IN_LAST_TWO_WEEKS_REPORT_TITLE = "Changes in last 2 weeks";
export const COST_BY_STATUS_REPORT_TITLE = "Cost by Status";
export const COST_SUMMARY_REPORT_TITLE = "Cost Summary";
export const DEVICES_BY_STATUS_REPORT_TITLE = "Devices by Status";
export const TOTAL_DEVICES_REPORT_TITLE = "Total Devices";

// DATA FIELDS
export const AREA_DATA_FIELD = "locale.area";
export const BUILDING_DATA_FIELD = "locale.building";
export const COST_DATA_FIELD = "device_type.cost";
export const COUNT_FIELD = 'count'
export const DEPLOYMENT_STATUS_FIELD = "deployment_status";
export const IP_ADDRESS_FIELD = 'ip_address'
export const NAME_DATA_FIELD = 'name';
export const ROOM_DATA_FIELD = "locale.room";
export const TOTAL_COST_FIELD = 'total_cost';
export const UPDATED_AT_DATA_FIELD = "last_update_time";
export const DNS_NAME_FIELD = 'dns_name'

// MAGIC NUMBERS
export const DEFAULT_PAGE_SIZE = 100;
export const BAR_CHART = 'bar'
export const LINE_CHART = 'line'
export const PIE_CHART = 'pie'

export const NULL_DEPLOYMENT_STATUS_ID = null;
export const NULL_DEVICE_TYPE_ID = null;
export const NULL_UPDATED_AT_DATE = null;