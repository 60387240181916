export function deploymentStatusLabel(device) {
    // Find the first element with "selected" attribute set to true
    const selectedElement = device['deployment_status'].find((deploymentStatus) => deploymentStatus.selected === true);

    // Extract the "name" field from the selected element (if found)
    return selectedElement?.name;
}

export function deviceTypeLabel(device) {
    return device['device_type']['device_type'];
}

export function updatedAtLabel(device) {
    return device['last_update_time']
}

/**
 * The device cost chart aggregates cost by deployment status
 *
 * @param device
 * @returns {*}
 */
export function deviceCostLabel(device) {
    return deploymentStatusLabel(device);
}