import ReportGenerator from "./report_generator";
import {
    AREA_DATA_FIELD,
    BUILDING_DATA_FIELD,
    CHANGES_IN_LAST_TWO_WEEKS_REPORT_TITLE,
    COST_DATA_FIELD,
    COUNT_FIELD,
    DEPLOYMENT_STATUS_FIELD,
    LINE_CHART,
    ROOM_DATA_FIELD,
    TABLE_ID,
    TOTAL_COST_FIELD,
    UPDATED_AT_DATA_FIELD
} from "../../constants/reports_constants";

export default class ChangesInLastTwoWeeksReportGenerator extends ReportGenerator {

    _getGraphTitle() {
        return CHANGES_IN_LAST_TWO_WEEKS_REPORT_TITLE;
    }

    _hideUnusedColumns() {
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('hideColumn', BUILDING_DATA_FIELD);
        table.bootstrapTable('hideColumn', COST_DATA_FIELD);
        table.bootstrapTable('hideColumn', COUNT_FIELD);
        table.bootstrapTable('hideColumn', DEPLOYMENT_STATUS_FIELD);
        table.bootstrapTable('hideColumn', TOTAL_COST_FIELD);
    }

    _showUsedColumns() {
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('showColumn', AREA_DATA_FIELD);
        table.bootstrapTable('showColumn', ROOM_DATA_FIELD);
        table.bootstrapTable('showColumn', UPDATED_AT_DATA_FIELD);
    }

    _getChartType() {
        return LINE_CHART;
    }

    _getDeviceQueryInputParameters(
        paginationToken,
        localeId = null,
        deviceTypeId = null,
        manufacturerId = null,
        modelId = null,
        deploymentStatusId = null,
        updatedAtDate = null
    ) {
        const baseQueryParameters = super._getDeviceQueryInputParameters(
            paginationToken,
            localeId,
            deviceTypeId,
            manufacturerId,
            modelId,
            deploymentStatusId,
            updatedAtDate
        );

        // Get the current timestamp
        const currentTimestamp = Date.now();

        const twoWeeksInMilliseconds = 2 * 7 * 24 * 60 * 60 * 1000;

        // Update the updated_after parameter to be two weeks ago
        baseQueryParameters['updated_after'] = (currentTimestamp - twoWeeksInMilliseconds).toString();

        // Update the updated_before parameter to be now
        baseQueryParameters['updated_before'] = currentTimestamp.toString();

        // Ask the server to decorate the response with areas and rooms
        baseQueryParameters['with_areas'] = 'true'
        baseQueryParameters['with_rooms'] = 'true'

        return baseQueryParameters;
    }

}