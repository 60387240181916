import { CHART_TITLE_ID, GRAPH_CANVAS_ID, TABLE_ID } from "../../constants/reports_constants";
import Chart from 'chart.js/auto'
import { getChartGenerator, hideReportSpinner } from "../reports";
import autocolors from 'chartjs-plugin-autocolors';
import { getDeviceQueryInputParameters } from "../../shared/tables/devices_table";

Chart.register(autocolors)

export default class ReportGenerator {

    displayReport(localeId = null, deviceTypeId = null, manufacturerId = null, modelId = null, deploymentStatusId = null) {
        this._updateChartTitle();
        this._hideUnusedColumns();
        this._showUsedColumns();
        this._clearTable();
        this._clearGraph();
        this._gatherDevicePageUpdateTableAndGraph(localeId, deviceTypeId, manufacturerId, modelId, deploymentStatusId);
    }

    _clearGraph() {
        // Get the Chart element. This will be undefined if one hasn't been rendered yet.
        const chart = Chart.getChart(GRAPH_CANVAS_ID);
        if (chart !== undefined) {
            chart.destroy();
        }
    }

    _clearTable() {
        // Clear the table
        $(`#${TABLE_ID}`).bootstrapTable('removeAll');
    }

    _getSelectedReportTitle() {
        return $("input[name='report_button']:checked").val();
    }

    _gatherDevicePageUpdateTableAndGraph(localeId, deviceTypeId, manufacturerId, modelId, deploymentStatusId) {
        // Find the table element. We'll be updating as we fetch pages of devices:
        const table = $(`#${TABLE_ID}`);

        // Create an object to store all of the gathered devices
        const matchingDevices = [];

        async function gatherDevicesAndUpdateTableAndGraph(
            matchingDevices,
            table,
            getQueryParameterCallback,
            createChartCallback,
            chartGenerator,
            tableDecoratorCallback,
            paginationToken = null
        ) {

            const queryParameters = getQueryParameterCallback(
                paginationToken,
                localeId,
                deviceTypeId,
                manufacturerId,
                modelId,
                deploymentStatusId
            );

            $.ajax({
                url: '/tables/devices/list',
                dataType: 'json',
                data: queryParameters
            }).promise().then((resultsJson) => {
                // If we found devices, append them to the table and add them to the return variable
                // table.bootstrapTable('append', resultsJson.devices);
                tableDecoratorCallback(resultsJson.devices);

                // append(...) creates a new array. Since we're using a return variable, we don't want to create a
                // new array each time.
                matchingDevices.push(...resultsJson.devices);
                // If the pagination token is null, we've found everything. Return out of here.
                // If the pagination token isn't null, there are more results. Let's trigger another query
                // hideReportSpinner hides BS spinner div called on reports.js
                if (resultsJson.pagination_token === null) {
                    createChartCallback(matchingDevices, chartGenerator);
                } else {
                    // Refresh the table. This ensures the DOM is up to date. We need this so things
                    // like the Cost Summary page, which searches for rows that already exist in the
                    // table, are computed correctly
                    table.bootstrapTable('refresh');

                    // Issue the next page's query
                    gatherDevicesAndUpdateTableAndGraph(
                        matchingDevices,
                        table,
                        getQueryParameterCallback,
                        createChartCallback,
                        chartGenerator,
                        tableDecoratorCallback,
                        resultsJson.pagination_token
                    );
                }
            }).catch((error) => {
                hideReportSpinner()
                console.error(`Unable to fetch devices!`);
                console.error(error);
            });
        }

        // Start recursion. I'm sure there's a better way of doing this than a callback...
        gatherDevicesAndUpdateTableAndGraph(
            matchingDevices,
            table,
            this._getDeviceQueryInputParameters,
            this._createChart,
            getChartGenerator(this._getSelectedReportTitle()),
            this._tableAggregator
        );
    }

    _updateChartTitle() {
        const graphTitle = this._getGraphTitle();
        const graphTitleHtmlElement = document.getElementById(CHART_TITLE_ID);

        // Update the graph title
        graphTitleHtmlElement.innerText = graphTitle;
    }

    _getGraphTitle() {
        throw Error('This method is supposed to be overridden!');
    }

    _hideUnusedColumns() {
        throw Error('This method is supposed to be overridden!');
    }

    _showUsedColumns() {
        throw Error('This method is supposed to be overridden!');
    }

    _getChartType() {
        throw Error('This method is supposed to be overridden!');
    }

    /**
     * Keep this around so that super classes can override it to modify the base behavior.
     * I couldn't think of a better way of sharing code between this controller and the
     * device_table_controller.js. I think we can do it by re-base classing both controllers
     * but there isn't a 1:1 mapping with their behavior (Reports don't look for default
     * values in hidden fields).
     *
     * @param paginationToken
     * @param localeId
     * @param deviceTypeId
     * @param manufacturerId
     * @param modelId
     * @param deploymentStatusId
     * @param updatedAtDate
     * @returns {{page_size: number}}
     * @private
     */
    _getDeviceQueryInputParameters(
        paginationToken,
        localeId,
        deviceTypeId = null,
        manufacturerId = null,
        modelId = null,
        deploymentStatusId = null,
        updatedAtDate = null
    ) {
        return getDeviceQueryInputParameters(paginationToken,
            localeId,
            deviceTypeId,
            manufacturerId,
            modelId,
            deploymentStatusId,
            updatedAtDate);
    }

    /**
     * This implementation simply adds each device to the table as its own row. Some reports
     * want to show data on the table aggregated at a higher level.
     *
     * @param devices an array of device metadata, as JSON
     * @private
     */
    _tableAggregator(devices) {
        // Find the table element. We'll be updating as we fetch pages of devices:
        const table = $(`#${TABLE_ID}`);

        // If we found devices, append them to the table and add them to the return variable
        table.bootstrapTable('append', devices);
    }

    /**
     * This method is not intended to be overridden. It renders the chart using
     * If you need a new ChartGenerator, create one in the getChartPresenter method in app/javascript/packs/reports/reports.js
     *
     * @param matchingDevices an array of devices that match the report's conditions.
     * @param chartGenerator a ChartGenerator class that tells us [1] what type of chart to render and [2] what data to display.
     * @private
     */

    _createChart(matchingDevices, chartGenerator) {
        if (chartGenerator.isChartEnabled()) {
            new Chart(
                document.getElementById(GRAPH_CANVAS_ID),
                {
                    type: chartGenerator.getChartType(),
                    options: {
                        animation: true,
                        plugins: {
                            legend: {
                                display: chartGenerator.getShowLegend()
                            },
                            tooltip: {
                                enabled: true
                            },
                            autocolors: {
                                mode: 'data'
                            },
                        },
                        layout: {
                            padding: 20
                        },
                    },
                    data: {
                        datasets: [{
                            data: chartGenerator.getChartData(matchingDevices)
                        }],
                        labels: chartGenerator.getChartLabels(matchingDevices)
                    }
                }
            );

            hideReportSpinner();
        } else {
            hideReportSpinner();
        }
    }

}