import {
    AREA_DATA_FIELD,
    BUILDING_DATA_FIELD,
    COST_DATA_FIELD,
    COUNT_FIELD,
    DEPLOYMENT_STATUS_FIELD,
    DEVICES_BY_STATUS_REPORT_TITLE,
    PIE_CHART,
    ROOM_DATA_FIELD,
    TABLE_ID,
    TOTAL_COST_FIELD,
    UPDATED_AT_DATA_FIELD
} from "../../constants/reports_constants";
import ReportGenerator from "./report_generator";

export default class DevicesByStatusReportGenerator extends ReportGenerator {

    _getGraphTitle() {
        return DEVICES_BY_STATUS_REPORT_TITLE;
    }

    _hideUnusedColumns() {
        // Intentionally left blank
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('hideColumn', BUILDING_DATA_FIELD);
        table.bootstrapTable('hideColumn', COST_DATA_FIELD);
        table.bootstrapTable('hideColumn', COUNT_FIELD);
        table.bootstrapTable('hideColumn', ROOM_DATA_FIELD);
        table.bootstrapTable('hideColumn', TOTAL_COST_FIELD);
        table.bootstrapTable('hideColumn', UPDATED_AT_DATA_FIELD);
    }

    _showUsedColumns() {
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('showColumn', AREA_DATA_FIELD);
        // table.bootstrapTable('showColumn', ROOM_DATA_FIELD);
        table.bootstrapTable('showColumn', DEPLOYMENT_STATUS_FIELD);
    }

    _getChartType() {
        return PIE_CHART;
    }

    _getDeviceQueryInputParameters(
        paginationToken,
        localeId = null,
        deviceTypeId = null,
        manufacturerId = null,
        modelId = null,
        deploymentStatusId = null,
        updatedAtDate = null
    ) {
        const baseQueryParameters = super._getDeviceQueryInputParameters(
            paginationToken,
            localeId,
            deviceTypeId,
            manufacturerId,
            modelId,
            deploymentStatusId,
            updatedAtDate
        );

        // Ask for areas and deployment statuses as well
        baseQueryParameters['with_areas'] = 'true'
        baseQueryParameters['with_deployment_status'] = 'true'

        return baseQueryParameters;
    }

}