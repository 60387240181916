import {
    AREA_DATA_FIELD,
    BAR_CHART,
    BUILDING_DATA_FIELD,
    COST_DATA_FIELD,
    COST_SUMMARY_REPORT_TITLE,
    COUNT_FIELD,
    DEPLOYMENT_STATUS_FIELD,
    IP_ADDRESS_FIELD,
    NAME_DATA_FIELD,
    ROOM_DATA_FIELD,
    TABLE_ID,
    TOTAL_COST_FIELD,
    UPDATED_AT_DATA_FIELD
} from "../../constants/reports_constants";
import ReportGenerator from "./report_generator";
import { getSelectedDeploymentStatusId } from "../helpers/deployment_status_helpers";
import {
    deviceCostValue,
    getLocaleStringLocale,
    getLocaleStringOptions
} from "../charts/data_factories/value_function";

export default class CostSummaryReportGenerator extends ReportGenerator {

    _getGraphTitle() {
        return COST_SUMMARY_REPORT_TITLE;
    }

    _hideUnusedColumns() {
        // Intentionally left blank
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('hideColumn', AREA_DATA_FIELD);
        table.bootstrapTable('hideColumn', ROOM_DATA_FIELD);
        table.bootstrapTable('hideColumn', UPDATED_AT_DATA_FIELD);
        table.bootstrapTable('hideColumn', NAME_DATA_FIELD);
        table.bootstrapTable('hideColumn', IP_ADDRESS_FIELD);
    }

    _showUsedColumns() {
        const table = $(`#${TABLE_ID}`);
        table.bootstrapTable('showColumn', BUILDING_DATA_FIELD);
        table.bootstrapTable('showColumn', COST_DATA_FIELD);
        table.bootstrapTable('showColumn', COUNT_FIELD);
        table.bootstrapTable('showColumn', DEPLOYMENT_STATUS_FIELD);
        table.bootstrapTable('showColumn', TOTAL_COST_FIELD);
    }

    _getChartType() {
        return BAR_CHART;
    }

    _getDeviceQueryInputParameters(
        paginationToken,
        localeId = null,
        deviceTypeId = null,
        manufacturerId = null,
        modelId = null,
        deploymentStatusId = null,
        updatedAtDate = null
    ) {
        const baseQueryParameters = super._getDeviceQueryInputParameters(
            paginationToken,
            localeId,
            deviceTypeId,
            manufacturerId,
            modelId,
            deploymentStatusId,
            updatedAtDate
        );

        // Ask for the buildings, deployment statuses, and costs as well
        baseQueryParameters['with_buildings'] = 'true'
        baseQueryParameters['with_deployment_status'] = 'true'
        baseQueryParameters['with_cost'] = 'true'

        return baseQueryParameters;
    }

    /**
     * This method is overwritten so each row in the table can be an aggregate of many devices.
     * In this case, we aggregate by device model, building, and deployment status
     * @param devices an array of device metadata, as JSON
     * @private
     */
    _tableAggregator(devices) {
        // Find the table element. We'll be updating as we fetch pages of devices:
        const table = $(`#${TABLE_ID}`);

        // This variable is used to store the aggregated device counts/cost before adding them to the table
        const newRows = []

        // Get the rows currently in the table. We'll search these to see if a [building, model, deployment status]
        // already has a row in the table
        const currentRows = table.bootstrapTable('getData');

        // Loop through each device. Aggregate the device data by building, model, and deployment status.
        for (const device of devices) {
            // Generate the ID used in this table
            let id = `${device['locale']['building']['id']}!${device['device_type']['model']['id']}!${getSelectedDeploymentStatusId(device)}`

            // Check if this [building, model, deployment status] exists on the table already
            let row = table.bootstrapTable('getRowByUniqueId', id);

            // Look for an existing row for this [building, model, deployment status]
            let currentRow = currentRows.find((row) => row.id === id)

            // Check if this [building, model, deployment status] exists in the newRows table
            let undisplayedRow = newRows.find((row) => row.id === id);

            if (currentRow) {
                // Update this row's count and total cost fields
                currentRow.count = currentRow.count + 1;
                currentRow.total_cost = (currentRow.count * deviceCostValue(device)).toLocaleString(getLocaleStringLocale(), getLocaleStringOptions());

                // Update the existing row
                table.bootstrapTable('updateByUniqueId', {
                    id: id,
                    currentRow
                });
            } else if (undisplayedRow) {
                // We don't have the current [building, model, deployment status] in the table but we have added it
                // to our newRows array. Update this undisplayed row's count and total cost.
                undisplayedRow.count = undisplayedRow.count + 1;
                undisplayedRow.total_cost = (undisplayedRow.count * deviceCostValue(device)).toLocaleString(getLocaleStringLocale(), getLocaleStringOptions());
            } else {
                // We don't have the current [building, model, deployment status] in the table. Add a new row and default
                // the count to 1 and the total_cost to the cost of a single unit
                newRows.push({
                    id: id,
                    device_type: device['device_type'],
                    deployment_status: device['deployment_status'],
                    locale: device['locale'],
                    count: 1,
                    total_cost: deviceCostValue(device).toLocaleString(getLocaleStringLocale(), getLocaleStringOptions())
                })
            }
        }

        // If we created new rows, add them to the end of the table:
        if (newRows.length > 0) {
            table.bootstrapTable('append', newRows);
        }
    }
}