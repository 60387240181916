import {
    BAR_CHART,
    CHANGES_IN_LAST_TWO_WEEKS_REPORT_TITLE,
    COST_BY_STATUS_REPORT_TITLE,
    COST_SUMMARY_REPORT_TITLE,
    DEVICES_BY_STATUS_REPORT_TITLE,
    LINE_CHART,
    PIE_CHART,
    TABLE_ID,
    TOTAL_DEVICES_REPORT_TITLE
} from "../constants/reports_constants";
import ChartGenerator from "./charts/chart_generator"
import CostByStatusReportGenerator from "./library/cost_by_status_report_generator";
import CostSummaryReportGenerator from "./library/cost_summary_report_generator";
import ChangesInLastTwoWeeksReportGenerator from "./library/changes_in_last_two_weeks_report_generator";
import DevicesByStatusReportGenerator from "./library/devices_by_status_report_generator";
import TotalDevicesReportGenerator from "./library/total_devices_report_generator";
import {
    costByByStatusLabelReducer,
    deploymentStatusLabelReducer,
    deviceTypeLabelReducer,
    updatedAtLabelReducer
} from "./charts/data_factories/label_reducer_functions";
import {
    deploymentStatusLabel,
    deviceCostLabel,
    deviceTypeLabel,
    updatedAtLabel
} from "./charts/data_factories/label_function";
import { counterValue, deviceCostValue } from "./charts/data_factories/value_function";
import NoChartGenerator from "./charts/no_chart_generator";

let get_chart_element = function (event) {
    const chart = Chartkick.charts['chart-1'].getChartObject().delay(500);
    const element = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    if (element) {
        $.ajax('find_report', {
            type: 'GET', dataType: 'script', data: {
                item: chart.data.labels[element[0].index],
                value: chart.data.datasets[element[0].datasetIndex].data[element[0].index],
                report_type: $("input[name='report_button']:checked").val(),
                location_id: $('#report_loc_select').val(),
                controller: $('#controller').val()
            }, success: function () {
                $('#report_spinner').hide()
                return $('#detailsHolder').bootstrapTable();
            }, error: function (jqXHR, textStatus, errorThrown) {
                $('#report_spinner').hide()
                return console.log("AJAX Error: " + textStatus);
            }
        });
    }
};

// $(document).on('change', "input[name='report_button']", select_report);
// $(document).on('change', "#report_loc_select", report_location);
// $(document).on('click', 'canvas', get_chart_element);

export function getReportGenerator(reportTitle) {
    // report spinner shows BS div, hidden when report object is returned in report_generator using hideReportSpinner()
    $('#report_spinner').attr('style', 'display:block')
    switch (reportTitle) {
        case COST_BY_STATUS_REPORT_TITLE:
            return new CostByStatusReportGenerator();
        case COST_SUMMARY_REPORT_TITLE:
            return new CostSummaryReportGenerator();
        case CHANGES_IN_LAST_TWO_WEEKS_REPORT_TITLE:
            return new ChangesInLastTwoWeeksReportGenerator();
        case DEVICES_BY_STATUS_REPORT_TITLE:
            return new DevicesByStatusReportGenerator();
        case TOTAL_DEVICES_REPORT_TITLE:
            return new TotalDevicesReportGenerator();
        default:
            throw new Error(`Unknown report type: [${reportTitle}]`);
    }
}

export function hideReportSpinner() {
    $('#report_spinner').attr('style', 'display:none')
}

export function getChartGenerator(reportTitle) {
    switch (reportTitle) {
        case COST_BY_STATUS_REPORT_TITLE:
            return new ChartGenerator(BAR_CHART, deviceCostLabel, costByByStatusLabelReducer, deviceCostValue);
        case COST_SUMMARY_REPORT_TITLE:
            return new NoChartGenerator();
        case CHANGES_IN_LAST_TWO_WEEKS_REPORT_TITLE:
            return new ChartGenerator(LINE_CHART, updatedAtLabel, updatedAtLabelReducer, counterValue);
        case DEVICES_BY_STATUS_REPORT_TITLE:
            return new ChartGenerator(PIE_CHART, deploymentStatusLabel, deploymentStatusLabelReducer, counterValue);
        case TOTAL_DEVICES_REPORT_TITLE:
            return new ChartGenerator(BAR_CHART, deviceTypeLabel, deviceTypeLabelReducer, counterValue)
        default:
            throw new Error(`Unknown report type: [${reportTitle}]`);
    }
}

let initializeTable = function () {
    $(`#${TABLE_ID}`).bootstrapTable({
        stickyHeader: true,
        stickyHeaderOffsetLeft: parseInt($('body').css('padding-left'), 10),
        stickyHeaderOffsetRight: parseInt($('body').css('padding-right'), 10),
        virtualScroll: false // This forces all rows to be rendered. It makes scrolling less responsive but allows us to use the export plugin
    });

    $('[aria-label="Export data"]').on("click", function (event) {
        return confirm('Large exports take time to generate and may slow browser for extended period. Continue?');
    })
}

$(document).on('turbolinks:load', initializeTable);