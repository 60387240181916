import DataFactory from "./data_factories/data_factory";
import { PIE_CHART } from "../../constants/reports_constants";

export default class ChartGenerator {

    constructor(chartType, labelFunction, labelReducerFunction, valueFunction) {
        this._chartType = chartType;
        this._dataFactory = new DataFactory(labelFunction, labelReducerFunction, valueFunction);
    }

    isChartEnabled() {
        return true;
    }

    getChartType() {
        return this._chartType;
    }

    getChartData(matchingDevices) {
        return this._dataFactory.getData(matchingDevices);
    }

    getChartLabels(matchingDevices) {
        return this._dataFactory.getLabels(matchingDevices);
    }

    getShowLegend() {
        return this.getChartType() === PIE_CHART;
    }

}